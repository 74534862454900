import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams} from '@angular/common/http';
import { Observable} from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { RoutePlan } from '../../models/logistic/routevisit';

export interface ItemFact {
  //itemNo?      : number;
  Id          : number;
  Fecha       : string;
  IdCliente   : string;
  NombCli     : string;
  Phone       : string;
  City        : string;
  check       : boolean;
}

export interface DataItemFact {
  result: ItemFact[],
  count: number;
}

export interface ReplenishmentItem {
  code: string,
  itemName:string,
  quantity:number,
  packagingType:string,
  packagingUnits:number
}


@Injectable({
  providedIn: 'root'
})
export class DispatchsService extends BaseService {

  constructor(injector: Injector,
              private titlecasePipe:TitleCasePipe) {
      super(injector);
  }

  transformName(data: any){
    for (let item of data){
      let fullName = this.titlecasePipe.transform(item.Name);
      item.Name=fullName;
    }
    return data;
  }
  getList(PageNumber: number, PageSize:number,Desde:string,Hasta:string,Id:string) : Observable<any[]> {
    let params: HttpParams = new HttpParams();

    if (Id) { params = params.append('id',Id); }
    if (Desde) { params = params.append('From',Desde); }
    if (Hasta) { params = params.append('To',Hasta); }
    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }

    //http://FE-NET.NET:8600/api/CustomerService/Routes/Pagging?UserId=76&PageNumber=1&PageSize=20&From=2021-01-01&To=2021-12-31
    //Pagging?UserId=1&PageNumber=1&PageSize=20&From=2021-01-01&To=2021-12-31
    //const url = this.getApiQuery(`Customer/Pagging`);
    const url = this.getApiQuery(`CustomerService/RoutePlan/Pagging`);
    return this._httpClient.get<any[]>(
        url,
        { params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }


  getItemsReplenishmentRequest(docId: string): Observable<ReplenishmentItem[]> {
    const url = this.getApiQuery('CustomerService/GetPendingDocitems');
    let params = new HttpParams();
    params = params.append('docId', docId);
    return this._httpClient.get<ReplenishmentItem[]>(url, {headers: this.getAuthorizationHeader(), params: params});
  }


  getListPending<T>(PageNumber: number, PageSize:number,From:string,To:string,Pending:number,Filter:string) : Observable<T> {
    let params: HttpParams = new HttpParams();
    /*
    if (Filter)     { params = params.append('SearchTerm',Filter); }
    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }
    const url = this.getApiQuery(`Customer/Pagging`);
    */
    //http://FE-NET.NET:8600/api/Invoices/Pending?From=2020-01-01&To=2020-01-31&PageNumber=1&PageSize=20&Pending=1&Filter=zone=%23customergroup=%23customer=


    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }
    if (From)       { params = params.append('From',From); }
    if (To)         { params = params.append('To',To); }
    if (Pending)    { params = params.append('Pending',Pending.toString()); }
    if (Filter)     { params = params.append('Filter',Filter); }

    const url = this.getApiQuery(`Invoices/Pending`);
    return this._httpClient.get(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    ) as  Observable<T>;
  }

  getListPendingAll<T>(From:string,To:string,Zona:number,Grupocli:number,Cliente:number) : Observable<T> {
    let params: HttpParams = new HttpParams();
    /*
    if (Filter)     { params = params.append('SearchTerm',Filter); }
    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }
    const url = this.getApiQuery(`Customer/Pagging`);
    */
    //http://FE-NET.NET:8600/api/Invoices/Pending?From=2020-01-01&To=2020-01-31&PageNumber=1&PageSize=20&Pending=1&Filter=zone=%23customergroup=%23customer=



    if (From)       { params = params.append('From',From); }
    if (To)         { params = params.append('To',To); }
    if (Zona)       { params = params.append('Zona',Zona.toString()); }
    if (Grupocli)   { params = params.append('Grupocli',Grupocli.toString()); }
    if (Cliente)    { params = params.append('Cliente',Cliente.toString()); }

    //const url = this.getApiQuery(`CustomerService/GetPendingDispatchDocs`);
    const url = this.getApiQuery(`CustomerService/GetPendingDocs`);
    return this._httpClient.get(
        url,
        {
          params: params,
          headers: this.getAuthorizationHeader()
        }
    ) as  Observable<T>;
  }

  createDispatch(record: RoutePlan) {
    const url = this.getApiQuery('Invoices/CreateRoutePlan');
    return this._httpClient.post(
      url,
      record,
      { headers: this.getAuthorizationHeader() }
    );
  }

  getRoutePlanById(id:number) {
    let params: HttpParams = new HttpParams();
    if (id) { params = params.append('ID',id.toString()); }

    const url = this.getApiQuery('CustomerService/RoutePlanById');
    return this._httpClient.get<RoutePlan>(
      url,
      {
        params: params,
        headers: this.getAuthorizationHeader()
      }
    );
  }

}
