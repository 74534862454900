import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from '@angular-devkit/core/node_modules/rxjs';
import { PagedResult } from 'src/app/models/Responses/PagedResult';
import { DocumentResourceParameters } from 'src/app/helpers/DocumentResourceParameters';

@Injectable({
  providedIn: 'root'
})
export class BankingmovementsService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
  }

    getList(PageNumber: number, PageSize:number,SearchTerm:string) : Observable<any[]> {
    let params: HttpParams = new HttpParams();

    
    if (SearchTerm) { params = params.append('SearchTerm',SearchTerm.toString()); }
    if (PageNumber) { params = params.append('PageNumber',PageNumber.toString()); }
    if (PageSize)   { params = params.append('PageSize',PageSize.toString()); }

    let url = this.getApiQuery(`Finance/MOVBANCO/Pagging`);
    return this._httpClient.get<any>(
        url,
        { 
          params: params,
          headers: this.getAuthorizationHeader()
        }
    );
  }
  
  getBankMov(args: DocumentResourceParameters): Observable<PagedResult<any>> {
    const url = this.getApiQuery('Finance/MOVBANCO/Pagging');
    let params = new HttpParams();
    if (args.UserId) {
      params = params.append('UserId', args.UserId);
    }

    if (args.Id_Per) {
      params = params.append('Id_Per', args.Id_Per);
    }

    if (args.From) {
      params = params.append('From', args.From);
    }

    if (args.To) {
      params = params.append('To', args.To);
    }

    if (args.DocId) {
      params = params.append('DocId', args.DocId);
    }
    if (args.Filter) {
      params = params.append('Filter', args.Filter);
    }
    params = params.append('PageNumber', args.PageNumber.toString());
    params = params.append('PageSize', args.PageSize.toString());

    return this._httpClient.get<PagedResult<any>>(url, { headers: this.getAuthorizationHeader(), params: params });

  }

}
