import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { dynamicRoutes } from './dynamic.routes';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { SharedModule } from '../shared/shared.module';
import { WidgetsModule } from '../widgets/widgets.module';

import { DatePipe } from '@angular/common';
import { SyncModule } from './../../sync.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadModule } from 'ng2-file-upload';

import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';

import { CatalogueComponent } from './catalogue/catalogue.component';

const dialogMock = {
  close: () => { }
 };

@NgModule({
  imports: [
    SharedModule,
    BsDatepickerModule,
    TooltipModule,
    CarouselModule,
    ModalModule,
    ButtonsModule,
    WidgetsModule,
    RouterModule.forChild(dynamicRoutes),

    SyncModule,
    FormsModule,
    //ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    MatDialogModule,
    FileUploadModule,

    TooltipModule,
    CarouselModule,
    ReactiveFormsModule,
    TreeViewModule

  ],
  declarations: [
    CatalogueComponent,
  ],
  exports: [
    BsDatepickerModule,
    CatalogueComponent,
    SyncModule,
  ],
  entryComponents: [
  ],
  providers: [
    DatePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef,useValue: {dialogMock} }
  ]
})
export class DynamicModule { }
